<template>
  <div class="container">
    <div class="wxPay" v-if="shopPay">
      <!-- <svg class="icon" viewBox="0 0 1024 1024" width="180" height="180">
        <path
          d="M390.21952 631.09248c-61.26464 34.01984-70.35136-19.09888-70.35136-19.09888l-76.78208-178.46656c-29.54368-84.80512 25.56928-38.23744 25.56928-38.23744s47.2896 35.63392 83.17952 57.34784c35.87072 21.71392 76.75776 6.3744 76.75776 6.3744l501.9648-230.7776C837.94688 113.4528 684.96256 38.4 511.76576 38.4 229.11104 38.4 0 238.13248 0 484.52864c0 141.72544 75.8656 267.8656 194.0352 349.62176l-21.31072 122.01856c0 0-10.38848 35.6224 25.61536 19.10016 24.53376-11.26528 87.0784-51.63392 124.30976-76.20224 58.53056 20.31616 122.2976 31.5968 189.14432 31.5968 282.63168 0 511.79008-199.73248 511.79008-446.13376 0-71.36896-19.31008-138.76864-53.51552-198.59456C810.14144 381.7792 438.15808 604.51712 390.21952 631.09248L390.21952 631.09248 390.21952 631.09248zM390.21952 631.09248"
          fill="#c7c6c6"
        ></path>
      </svg> -->
      支付拉起中

      <div class="letter-holder">
        <div class="l-1 letter"></div>
        <div class="l-2 letter"></div>
        <div class="l-3 letter"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {payParams,cacheQuery }from '@/api/api'
import config from "@/config";
export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  // components: { MyDialog, Keyboard },
  data: function (){
    return {
      merchantName: '',  // 付款的商户默认
      avatar: require("../../assets/icon/wx.svg"), // 商户头像默认
      amount: 1,  // 支付金额默认
      resData : {},
      wxImg: require("../../assets/icon/wx.svg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      shopPay:!0,
      returnUrl:null,
    }
  },

  mounted() {
    this.setPayOrderInfo(true); //获取订单信息 & 调起支付插件
    this.getOrderInfo()
  },

  methods: {
    getOrderInfo(){
            const params={
            }
      cacheQuery(params).then(res=>{
        console.log(res)
      }).catch(res=>{

      })
    },
    setPayOrderInfo(isAutoPay){
      const that = this
      const params={
      }
      payParams(params).then(res => {
        console.log(res)
        if(res.payInfo){
          this.payOrderInfo=res
          if(isAutoPay){
            that.wxPay(res.payInfo)
          }
        }else{
          that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.errMsg}})
        }
      }).catch(res => {
        console.log(res)
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },



    wxPay(data) {
      // console.log(new Date().getTime(),'wxPAY')
      const jsapi_pay_info = JSON.parse(data);
      const _this = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            _this.onBridgeReady(jsapi_pay_info),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent(
            "WeixinJSBridgeReady",
            _this.onBridgeReady(jsapi_pay_info)
          );
          document.attachEvent(
            "onWeixinJSBridgeReady",
            _this.onBridgeReady(jsapi_pay_info)
          );
        }
      } else {
        _this.onBridgeReady(jsapi_pay_info);
      }
    },
    onBridgeReady(result, data) {
      var _self = this;
      setTimeout(() => {
        this.shopPay = false;
      }, 200);
      // console.log(new Date().getTime(),'wxPAY2')
      WeixinJSBridge.invoke("getBrandWCPayRequest", { ...result }, (res) => {
        console.log(res);
        // console.log(new Date().getTime(),'wxPAY3')
        _self.showTap = true;
        _self.isClick = false;
        if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // //重定向
            if (_self.payOrderInfo.returnUrl) {
              window.location.href = _self.payOrderInfo.returnUrl;
            }else{
              alert('支付成功！');
              window.WeixinJSBridge.call('closeWindow')
            }

        }
        if (res.err_msg == "get_brand_wcpay_request:cancel") {
          alert("支付取消");
          window.WeixinJSBridge.call('closeWindow')
        }
        if (res.err_msg == "get_brand_wcpay_request:fail") {
          alert('支付失败:' + JSON.stringify(res))
          window.WeixinJSBridge.call('closeWindow')
        }
        if (res.err_msg == "total_fee") {
          alert('缺少参数')
          window.WeixinJSBridge.call('closeWindow')
        }
      });
    },
    /* 唤醒微信支付*/
    onBridgeReady2() {

      let that = this;

      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
          'getBrandWCPayRequest', JSON.parse(that.resData.payInfo),
          function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // //重定向
              if(that.payOrderInfo.returnUrl){
                location.href = that.payOrderInfo.returnUrl;
              }else{
                alert('支付成功！');
                window.WeixinJSBridge.call('closeWindow')
              }

            }
            if (res.err_msg == "get_brand_wcpay_request:cancel") {
              alert("支付取消");
              window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "get_brand_wcpay_request:fail") {
              alert('支付失败:' + JSON.stringify(res))
              window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "total_fee") {
              alert('缺少参数')
              window.WeixinJSBridge.call('closeWindow')
            }
          }
      );
    },

  }


}
</script>
<style lang="scss" scoped>
 @import './pay.css';
 
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #efefef;
}
.wxPay {
  padding: 24px 34px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 100px;
  color: #fff;
  font-size: 14px;
  .icon {
    margin-bottom: 14px;
    width: 30px;
    height: 30px;
  }
  .letter-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
  }
  .letter {
    animation-name: loadingF;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: linear;
    width: 5px;
    height: 5px;
    margin: 0 2px;
    border-radius: 20px;

    background-color: #fff;
  }
  .l-1 {
    animation-delay: 0.28s;
  }

  .l-2 {
    animation-delay: 0.4s;
  }

  .l-3 {
    animation-delay: 0.52s;
  }
  @keyframes loadingF {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>